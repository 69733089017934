import Vue from 'vue'
import { CentraSearchController } from '@made-people/nuxt-centra-search'
import { BreadcrumbLoader } from '@made-people/nuxt-breadcrumbs'
import Headline from '~/components/headline/Headline.vue'
import Breadcrumbs from '~/components/breadcrumbs/Breadcrumbs.vue'
import Button from '~/components/button/Button.vue'
import ProductBadge from '~/components/product-badge/ProductBadge.vue'
import EQPECentraSearchController from '~/components/eqpe-centra-search-controller/EQPECentraSearchController.vue'
import StoryblokElevateSearchController from '~/components/storyblok-elevate-search-controller/StoryblokElevateSearchController.vue'
import ElevateProductListSwipeable from '~/components/elevate-product-list-swipeable/ElevateProductListSwipeable.vue'

Vue.component('MpLink', () =>
  import('@made-people/centra-storyblok-nuxt-shared/lib/components/MpLink.vue')
)
Vue.component('Vue2Image', () => import('@made-people/vue2-image'))
Vue.component('Vue2Video', () => import('@made-people/vue2-video'))

Vue.component('CentraSearchController', CentraSearchController.default)
Vue.component('EQPECentraSearchController', EQPECentraSearchController)
Vue.component(
  'StoryblokElevateSearchController',
  StoryblokElevateSearchController
)
Vue.component('ElevateProductListSwipeable', ElevateProductListSwipeable)
Vue.component('SearchResultsWrapper', () =>
  import('~/components/search/SearchResultsWrapper.vue')
)

// Main views
/* eslint-disable */
Vue.component('storyblok', () => import('~/views/Storyblok.vue'))
Vue.component('product', () => import('~/views/Product.vue'))
Vue.component('ProductView', () => import('~/views/Product.vue'))
Vue.component('search', () => import('~/views/Search.vue'))
/* eslint-enable */

Vue.component('ProductListHeader', () =>
  import('~/components/product-list-header/ProductListHeader.vue')
)
Vue.component('ProductList', () =>
  import('~/components/product-list/ProductList.vue')
)

Vue.component('ProductListSwipeable', () =>
  import('~/components/product-list-swipeable/ProductListSwipeable.vue')
)
Vue.component('ProductListSwipeableWrapper', () =>
  import('~/components/product-list-swipeable/ProductListSwipeableWrapper.vue')
)
Vue.component('CategoryPickerSlideable', () =>
  import('~/components/category-picker-slideable/CategoryPickerSlideable.vue')
)
Vue.component('ProgressBar', () =>
  import('~/components/progress-bar/ProgressBar.vue')
)

// Header
// Vue.component('Header', () => import('~/components/header/Header.vue'))
Vue.component('Topbar', () => import('~/components/header/topbar/Topbar.vue'))
Vue.component('Hero', () => import('~/components/hero/Hero.vue'))
Vue.component('VideoHero', () =>
  import('~/components/video-hero/VideoHero.vue')
)
Vue.component('UspContainer', () => import('~/components/usp/UspContainer.vue'))
Vue.component('Usp', () => import('~/components/usp/Usp.vue'))
Vue.component('SizeGuideModal', () =>
  import('~/components/size-guide-modal/SizeGuideModal.vue')
)
Vue.component('TextBlock', () =>
  import('~/components/text-block/TextBlock.vue')
)
Vue.component('Toast', () => import('~/components/toast/Toast.vue'))
Vue.component('Title', () => import('~/components/title/Title.vue'))
Vue.component('Link', () => import('~/components/link/Link.vue'))
Vue.component('Select', () => import('~/components/select/Select.vue'))
Vue.component('SelectOption', () =>
  import('~/components/select/SelectOption.vue')
)

// // Transitions
// Vue.component('SlideInTransitionRight', () =>
//   import('~/components/transitions/SlideInTransitionRight.vue')
// )
// Vue.component('SlideInTransitionTop', () =>
//   import('~/components/transitions/SlideInTransitionTop.vue')
// )
// Vue.component('SlideInTransitionLeft', () =>
//   import('~/components/transitions/SlideInTransitionLeft.vue')
// )
// Vue.component('FadeInTransition', () =>
//   import('~/components/transitions/FadeInTransition.vue')
// )

Vue.component('Headline', Headline)
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('BreadcrumbLoader', BreadcrumbLoader.default)

Vue.component('CategorySlider', () =>
  import('~/components/category-slider/CategorySlider.vue')
)
Vue.component('InfoAccordion', () =>
  import('~/components/info-accordion/InfoAccordion.vue')
)
Vue.component('Button', Button)
Vue.component('ProductBadge', ProductBadge)
Vue.component('TextComponent', () =>
  import('~/components/text-component/TextComponent.vue')
)
Vue.component('MenuLinks', () => import('~/components/header/MenuLinks.vue'))
Vue.component('TabNav', () => import('~/components/tab/TabNav.vue'))

Vue.component('TabButton', () => import('~/components/tab/TabButton.vue'))

Vue.component('TabPanel', () => import('~/components/tab/TabPanel.vue'))

Vue.component('Dialog', () => import('~/components/dialog/Dialog.vue'))
Vue.component('DialogHeader', () =>
  import('~/components/dialog/DialogHeader.vue')
)
Vue.component('DialogContent', () =>
  import('~/components/dialog/DialogContent.vue')
)
Vue.component('Wishlist', () => import('~/components/wishlist/Wishlist'))
Vue.component('WishlistIcon', () =>
  import('~/components/wishlist/WishlistIcon')
)
Vue.component('CartUpsellBlock', () =>
  import('~/components/checkout/CartUpsellBlock')
)
Vue.component('Grid', () => import('~/components/grid/Grid.vue'))
Vue.component('TransitionExpand', () =>
  import('~/components/transition-expand/TransitionExpand.vue')
)
Vue.component('NewsletterPopup', () =>
  import('~/components/newsletter-popup/NewsletterPopup.vue')
)

Vue.component('PromoBlock', () =>
  import('~/components/promo-block/PromoBlock.vue')
)

Vue.component('Flowbox', () => import('~/components/flowbox/Flowbox.vue'))

Vue.component('TestFreaksAllReviews', () =>
  import('~/components/testfreaks-all-reviews/TestFreaksAllReviews.vue')
)
