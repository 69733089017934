<template>
  <div class="mb-3">
    <mp-link
      :to="$u(product.url)"
      class="flex flex-col mb-2"
      @click.native="trackClick"
    >
      <div class="flex justify-between items-center flex-row">
        <span
          v-if="product.gender"
          class="mb-1 text-xs text-left text-darkGray tracking-wider"
        >
          {{ product.gender }}
        </span>
        <div class="testfreaks-item" :data-family-id="familyId"></div>
      </div>
      <span
        class="mb-2 text-sm lg:text-base font-semibold tracking-wider text-left"
      >
        {{ product.silkProductName }}
      </span>
      <div class="flex text-sm lg:text-base text-left">
        <span v-if="product.discounted" class="mr-2 text-black">{{
          product.price
        }}</span>
        <span v-else>{{ product.price }}</span>

        <span v-if="product.discounted" class="line-through text-darkGray">{{
          product.priceBeforeDiscount
        }}</span>
      </div>
    </mp-link>
    <span
      v-if="displayAmountOfColors && product.isOneSize"
      class="block mb-3 text-sm tracking-widest text-left"
    >
      {{ $t('One_Color') }}
    </span>

    <ProductCardSwatches
      :color-swatches="product.swatchProducts"
      :product-name="product.name"
    />
  </div>
</template>
<script>
import ElevateMixin from '../../mixins/ElevateMixin'
import ProductCardSwatches from './ProductCardSwatches.vue'

export default {
  name: 'ProductCardDetails',
  components: { ProductCardSwatches },
  mixins: [ElevateMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    contextWishlist: {
      type: Boolean,
      default: false,
    },
    contextSearch: {
      type: Boolean,
      default: false,
    },
    displaySwatches: {
      type: Boolean,
      default: true,
    },
    showProductType: {
      type: Boolean,
      default: false,
    },
    isSearchPage: {
      type: Boolean,
      default: false,
    },
    gtmListName: {
      type: String,
      default: '',
    },
  },
  computed: {
    displayAmountOfColors: ({
      colorSwatches,
      contextSearch,
      displaySwatches,
    }) => colorSwatches && !contextSearch && displaySwatches,
    familyId: ({ product }) => product.productSku.replace('P', ''),
  },
  methods: {
    trackClick() {
      this.gtm_trackProductClick(this.product, this.gtmListName)
      this.clickNotification(this.product.ticket, this.product.product)
    },
  },
}
</script>
