export default {
  namespaced: true,
  state() {
    return {
      pdpLowestPriceModalOpen: false,
    }
  },
  mutations: {
    /**
     * Pdp Lowest Price Modal
     */
    showPdpLowestPriceModal(state) {
      // Close everything else
      state.miniCartOpen = false
      state.mobileMenuOpen = false
      state.mobileProductFilterOpen = false
      state.hotspotProductsOpen = null
      state.productRestockModalOpen = false
      state.sizeSelectorOpen = false
      state.countrySelectorOpen = false
      state.sizeguideModalOpen = false

      // Open Lowest Price modal
      state.modalOpen = true
      state.pdpLowestPriceModalOpen = true
      state.overlayIndex = 11
    },
    hidePdpLowestPriceModal(state) {
      state.modalOpen = false
      state.pdpLowestPriceModalOpen = false
      state.overlayIndex = 0
    },
  },
  actions: {
    showPdpLowestPriceModal({ commit }) {
      commit('showPdpLowestPriceModal')
    },
    hidePdpLowestPriceModal({ commit }) {
      commit('hidePdpLowestPriceModal')
    },
  },
}
